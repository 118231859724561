<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">ADI and Spec Grading Quiz</h2>

      <p class="mb-5">
        <b>Review the Letter Grade Requirements section of the Course Syllabus</b>. You earned the
        following assessments on your assignments in this course. What grade did you earn?
      </p>

      <ul class="mb-2 ml-4">
        <li class="mb-1">Earned over a 95% on all Pre-Lab Quizzes.</li>
        <li class="mb-1">
          Earned the following percentages on the grading bundles: Safety, 93%; Objective or Purpose
          & Concepts, 82%; Procedure, 94%, Observations, 79%; Data Analysis, 74%; Argumentation,
          83%, Exit Quizzes, 84%.
        </li>
        <li class="mb-1">
          Earned the following percentages on the final exam sections: Safety, 88%; Technique, 85%;
          Argumentation, 79%
        </li>
      </ul>

      <p class="mb-n2">
        Grade:
        <v-select
          v-model="inputs.grade"
          :items="gradeOptions"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 100px"
          item-text="text"
          item-value="value"
          label="Select:"
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        grade: null,
      },
      gradeOptions: [
        {text: 'A+', value: 'A+'},
        {text: 'A', value: 'A'},
        {text: 'A-', value: 'A-'},
        {text: 'B+', value: 'B+'},
        {text: 'B', value: 'B'},
        {text: 'B-', value: 'B-'},
        {text: 'C+', value: 'C+'},
        {text: 'C', value: 'C'},
        {text: 'C-', value: 'C-'},
        {text: 'D+', value: 'D+'},
        {text: 'D', value: 'D'},
        {text: 'D-', value: 'D-'},
        {text: 'F', value: 'F'},
      ],
    };
  },
};
</script>
<style scoped></style>
